import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/_rMWoWw9VH8"
    bibleGroupSrc="https://pdfhost.io/v/9ETgz5Wo5_Microsoft_Word_Bible_Group_Homework_516docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Joy-spreading Humility - The Pursuit of Happiness" />
  </Layout>
)

export default SermonPost
